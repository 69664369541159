<template>
  <div class="info-block pt-[20px] pr-[22px] pb-[16px] pl-[16px] bg-[#345375]/[0.55] border-white/[0.1] border rounded-[10px] text-left min-h-[253px]">
    <div class="relative">
      <div class="font-[500] text-[14px] leading-[16px] mb-[12px] relative flex flex-wrap">
        <img src="@/assets/icons/icon-info-block.svg" class="mr-10">
        Information
      </div>
      <div v-if="currency === 'EUR' && method === 'SEPA'">
        <div class="text-[10px] leading-[14px] text-white">
          <p class="!mb-[6px] decor-dot">Other banks used during the transfer may charge additional fees and are out of our control.</p>
          <p class="!mb-[6px] decor-dot">
            The name on the bank account you are {{ operation === 'withdrawal' ? 'withdrawing' : 'depositing' }} to must match the name
            entered during the verification of this Kauri Finance account.
          </p>
          <p class="!mb-[6px] decor-dot" v-if="operation === 'withdrawal'"> Withdrawals can take up to 72 hours.</p>
        </div>
      </div>
      <div v-else-if="['SWIFT'].some(el=>el === method)">
        <div class="text-[10px] leading-[14px] text-white">
          <p class="!mb-[6px] decor-dot">Other banks used during the transfer may charge additional fees and are out of our control.</p>
          <p class="!mb-[6px] decor-dot">
            The name on the bank account you are {{ operation === 'withdrawal' ? 'withdrawing' : 'depositing' }} to must match the name
            entered during the verification of this Kauri Finance account.
          </p>
          <p class="!mb-[6px] decor-dot"> Withdrawals can take up to 72 hours.</p>
        </div>
      </div>
      <div v-else-if="['CASHBOX_BREINROCK'].some(el=>el === method)">
        <div class="text-[10px] leading-[14px] text-white" v-if="operation === 'withdrawal'">
          <p class="!mb-[6px] decor-dot">Enter your Breinrock Internal banking details.</p>
          <p class="!mb-[6px] decor-dot">Withdrawals can take up to 24 hours.</p>
        </div>
        <div class="text-[10px] leading-[14px] text-white" v-if="operation === 'deposit'">
          <p class="!mb-[6px] decor-dot">Create a Deposit order with the necessary data for your account.</p>
          <p class="!mb-[6px] decor-dot">Do Transfer Between Users at Breinrock.com by specifying:</p>
          <p class="!mb-[6px] !text-red-500 !ml-[6px]">Username - YP30155</p>
          <p class="!mb-[6px] !text-red-500 !ml-[6px]">Account - {{breinrockAccount}}</p>
        </div>
      </div>
      <div v-else-if="method === 'GATEWAY'">
        <div class="text-[10px] leading-[14px] text-white">
          <p class="!mb-[6px] decor-dot">Your bank may charge additional fees.</p>
          <p class="!mb-[6px] decor-dot">Please consider the min and max limits on your card.</p>
        </div>
      </div>
      <div v-else-if="method === 'CROSS_ACCOUNT'">
        <div class="text-[10px] leading-[14px] text-white">
          <p class="mb-[6px] decor-dot">Transfers within the platform between Kauri.Finance users</p>
        </div>
      </div>
      <div v-else>-</div>
      <div class="limits-grid">
        <div class="p-[12px] rounded-[8px] bg-[#2B4665]">
          <div class="mb-[8px]">
            <div class="flex items-center flex-wrap text-gray-600/[0.8] text-[12px] leading-[14px] mb-[3px]">
              <img src="@/assets/icons/icon-min-arrow.svg" class="mr-[5px]"> Minimum {{ operation }}:
            </div>
            <div class="text-[12px] leading-[14px] text-white uppercase">{{ limits.min_amount ? limits.min_amount : '0' }} {{ currency }}</div>
          </div>
          <div>
            <div class="text-gray-600/[0.8] text-[12px] leading-[14px] mb-[3px]">Fee:</div>
            <div class="text-[10px] leading-[14px] text-white">
              <div class="text-[12px] leading-[14px] text-white uppercase">{{ computedFeeString }}</div>
            </div>
          </div>
        </div>
        <div class="p-[12px] rounded-[8px] bg-[#2B4665]">
          <div>
            <div class="flex items-center flex-wrap text-gray-600/[0.8] text-[12px] leading-[14px] mb-[3px]">
              <img src="@/assets/icons/icon-max-arrow.svg" class="mr-[5px]">  Maximum {{ operation }}:
            </div>
            <div class="text-[12px] leading-[14px] text-white uppercase">{{ limits.max_amount ? limits.max_amount : '-' }} {{ limits.max_amount ? currency : '' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InfoPseudoIcon } from '@/assets/icons/arrows';
export default {
  components: {
    InfoPseudoIcon,
  },
  props: {
    operation: {
      type: String,
      default: 'withdrawal',
    },
    currency: {
      type: String,
      default: 'USD',
    },
    method: {
      type: String,
    },
    limits: {
      type: [Array, Object],
      default: () => {},
    },
    fees: {
      type: [Array, Object],
      default: () => {},
    },
  },
  computed: {
    computedFeeString() {
      const { static_fee, percent_fee } = this.fees;
      const currency = this.currency
      if (static_fee == undefined || percent_fee == undefined) return '-';
      if (percent_fee && static_fee) {
        return percent_fee + '% + ' + static_fee + ' ' + currency;
      } else if (percent_fee && !static_fee) {
        return percent_fee + '%';
      } else if (!percent_fee && static_fee) {
        return static_fee + ' ' + currency;
      }      
      return '0 ' + currency;
    },
    breinrockAccount() {
      let currencySufix = "";
      if (this.currency == "USD") currencySufix = "USD";
      return `30155${currencySufix}`;
    }
  },
};
</script>

<style lang="scss" scoped>
.info-block {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('~@/assets/icons/icon-decor-info-block.svg');
    width: 150px;
    height: 179px;
  }
}
.decor-dot {
  position: relative;
  padding: 0 0 0 15px;

  &:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    width: 4px;
    height: 4px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgb(255 255 255 / 10%);
  }
}

.limits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 14px;
  margin: 11px 0 0;
}
</style>
