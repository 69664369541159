<template>
  <div class="info-block pt-[20px] pr-[22px] pb-[16px] pl-[16px] bg-[#345375]/[0.55] border-white/[0.1] border rounded-[10px] text-left min-h-[253px]">
    <div class="Limits_wrapper">
      <div class="font-[500] text-[14px] leading-[16px] mb-[12px] relative flex flex-wrap">
        <img src="@/assets/icons/icon-info-block.svg" class="mr-10">
        Information
      </div>
      <template v-if="isNetworkSelect">
        <div class="mb-[15px] last:mb-0">
          <div class="text-[10px] leading-[14px] text-white" v-if="operation === 'withdrawal'">
            <p class="!mb-[6px] decor-dot">
              Do not withdraw directly to a crowdfund or ICO address, as your account will not be credited with tokens from such sales
            </p>
            <p class="!mb-[6px] decor-dot" v-if="networkComputed">
              Ensure the network is <span class="text-red-500">{{ networkComputed }}</span>
            </p>
          </div>
          <div class="text-[10px] leading-[14px] text-white last:mb-0" v-else>
            <p class="!mb-[6px] decor-dot">
              Send <strong class="text-[12px]">Only {{ currency }}</strong> to this deposit address
            </p>
            <p class="!mb-[6px] decor-dot">
              Sending coin or token other than {{ currency }} to this address may result in the loss of your deposit
            </p>
            <p class="!mb-[6px] decor-dot">
              Coins will be deposited after <span class="text-blue-500">{{ confirmationsCount }}</span> network confirmations
            </p>
            <p class="!mb-[6px] decor-dot" v-if="networkComputed">
              Ensure the network is <span class="text-red-500">{{ networkComputed }}</span>
            </p>
          </div>
        </div>
        <div class="Limits_grid relative z-[999]" v-if="operation === 'withdrawal' && limits">
          <div class="p-[12px] rounded-[8px] bg-[#2B4665]">
            <div class="mb-[8px]">
              <div class="flex items-center flex-wrap text-gray-600/[0.8] text-[12px] leading-[14px] mb-[3px]">
                <img src="@/assets/icons/icon-min-arrow.svg" class="mr-[5px]"> Minimum withdrawal:
              </div>
              <div class="text-[12px] leading-[14px] text-white uppercase">{{ limits.min_amount }} {{ currency }}</div>
            </div>
            <template v-if="fees">
              <div class="flex items-center flex-wrap text-gray-600/[0.8] text-[12px] leading-[14px] mb-[3px]">Fee:</div>
              <div class="text-[12px] leading-[14px] text-white uppercase">{{ computedFeeString }}</div>
            </template>
          </div>
          <div class="p-[12px] rounded-[8px] bg-[#2B4665]">
            <div class="flex items-center flex-wrap text-gray-600/[0.8] text-[12px] leading-[14px] mb-[3px]">
              <img src="@/assets/icons/icon-max-arrow.svg" class="mr-[5px]"> Maximum withdrawal:
            </div>
            <div class="text-[12px] leading-[14px] text-white uppercase">{{ limits.max_amount }} {{ currency }}</div>
          </div>
        </div>
      </template>
      <div class="mb-[15px] last:mb-0" v-else>
        <div class="text-[10px] leading-[14px] text-white last:mb-0">
          <p class="!mb-[6px] decor-dot">Please choose network</p>
        </div>        
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { InfoPseudoIcon } from '@/assets/icons/arrows';
export default {
  components: {
    InfoPseudoIcon,    
  },
  props: {
    currency: {
      type: String,
      default: 'BTC',
    },
    fees: {
      type: Object,
      // required: true,
    },
    limits: {
      type: Object,
      default: () => {},
    },
    operation: {
      type: String,
      default: 'withdrawal',
    },
    network: {
      type: String,
      default: '',
    },
  },
  computed: {    
    ...mapState('user', ['userDepositRules']),
    // temp hardcode (?). This value most likely not gonna change,
    // its unnecessary to grab it from deep-deep object in userDepositRules
    isNetworkSelect() {
      // return this.network != 'Please choose network' && this.network;
      // return this.network != 'Please choose network' && this.network != '';
      return this.network != 'Please choose network';
    },
    networkComputed() {
      return this.network || false;
    },
    confirmationsCount() {
      if (this.userDepositRules && this.operation === 'deposit') {
        return (
          (this.userDepositRules[this.currency] &&
            this.userDepositRules[this.currency]['GATEWAY'] &&
            this.userDepositRules[this.currency]['GATEWAY']['P2P'] &&
            this.userDepositRules[this.currency]['GATEWAY']['P2P'].confirmations_count) ||
          '-'
        );
      }
      return 1;
    },
    computedFeeString() {
      const { static_fee, percent_fee } = this.fees;
      const currency = this.currency
      if (static_fee == undefined || percent_fee == undefined) return '-';
      if (percent_fee && static_fee) {
        return percent_fee + '% + ' + static_fee + ' ' + currency;
      } else if (percent_fee && !static_fee) {
        return percent_fee + '%';
      } else if (!percent_fee && static_fee) {
        return static_fee + ' ' + currency;
      }      
      return '0 ' + currency;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-block {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('~@/assets/icons/icon-decor-info-block.svg');
    width: 150px;
    height: 179px;
  }
}

.decor-dot {
  position: relative;
  padding: 0 0 0 15px;

  &:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    width: 4px;
    height: 4px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgb(255 255 255 / 10%);
  }
}

.Limits_details {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.Limits_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 14px;
  margin: 11px 0 0;
}
</style>
