<template>
  <div class="grow m-[0_0_20px] md:m-[0_30px_0_0]">
    <div class="WalletMethod_title">Confirm</div>
    <div class="WalletMethod_separator !mb-[20px]"></div>
    <TextFieldInput
      :value="depositAndWithdrawalCashboxBreinrockForm.username"
      name="username"
      label="Breinrock username"
      hideDetails
      class="mb-[15px]"
      disabled
    />
    <TextFieldInput
      :value="depositAndWithdrawalCashboxBreinrockForm.account_number"
      name="account_number"
      label="Breinrock account number"
      hideDetails
      class="mb-[15px]"
      disabled
    />
    <TextFieldInput
      :value="depositAndWithdrawalCashboxBreinrockForm.amount"
      name="amount"
      type="number"
      label="You get"
      hideDetails
      class="mb-[15px]"
      disabled
    />
    <TextArea
      class="mt-[10px] mb-[15px]"
      v-if="depositAndWithdrawalCashboxBreinrockForm.comment"
      :value="depositAndWithdrawalCashboxBreinrockForm.comment"
      name="comment"
      label="Comment"
      hideDetails
      disabled
    />
    <div class="flex gap-20">
      <OutlineButton
        class="Cashbox_submit"
        text="Confirm"
        :disabled="buttonDisableConfirm"
        fullWidth
        @click="handleSubmit"
      />
      <Button text="cancel"
        size="large"
        fullWidth
        @click="handleCancel"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import TextArea from '@/components/_common/FormElements/TextArea/TextArea';
import Button from "@/components/_common/FormElements/Button/Button";

export default {
  props: {
    buttonDisableConfirm: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextFieldInput,
    OutlineButton,
    TextArea,
    Button,
  },
  computed: {
    ...mapState('depositAndWithdrawal', ['depositAndWithdrawalCashboxBreinrockForm']),
  },
  methods: {
    handleSubmit() {
      this.$emit( "confirmCashboxBreinrock" );
    },
    handleCancel() {
      this.$emit( "cancelCashboxBreinrock" );
    }
  },
};
</script>
