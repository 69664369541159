<template>
  <div class="TextArea_root" :class="[{ 'TextArea_root--hasShadow': hasShadow }]">
    <div class="TextArea_label" :class="[{ 'TextArea_label--bold': bold }]">
      <label v-if="label" :for="name">{{ label }}</label>
    </div>
    <v-textarea
      :value="value"
      :name="name"
      :label="placeholder"
      :rows="rows"
      dark
      solo
      dense
      :rules="rules"
      :hide-details="hideDetails"
      @input="handleInput"
      validate-on-blur
      no-resize
      :disabled="disabled"
    >
      {{ value }}
    </v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    rows: {
      type: String,
      default: "5",
    },
    value: {
      type: String,
      default: '',
    },
    dark: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasShadow: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: [ String, Boolean ],
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
.TextArea_root {
  width: 100%;

  .TextArea_label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      text-align: left;
      color: #fff;
      margin-bottom: 10px;
    }

    &--bold {
      label {
        font-weight: 500;
      }
    }
  }
  .v-textarea.v-input--dense.v-text-field {
    width: 100%;

    .v-input__control {
      min-height: 46px;

      .v-input__slot {
        border: 1px solid transparent;
        background: rgba(#fff, 0.04);
        box-shadow: none;
        border-radius: 4px;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        .v-text-field__slot {
          textarea {
            caret-color: #fff;
          }
          textarea:-webkit-autofill,
          textarea:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
          }
          textarea:-webkit-internal-autofill {
            color: #fff !important;
          }
        }
      }
      .v-text-field__details {
        padding: 0;
        .v-messages.error--text {
          font-size: 14px;
          line-height: 16px;
          color: #ff556d;
        }
      }
    }
    &.error--text {
      .v-input__control {
        .v-input__slot {
          border: 1px solid #ff556d;
          background: rgba(255, 85, 109, 0.04);
          .v-input__append-inner {
            .v-input__icon {
              .v-icon {
                color: #ff556d;
              }
            }
          }
        }
      }
    }
  }
  &--hasShadow {
    .v-input.v-input--dense.v-text-field {
      .v-input__control {
        .v-input__slot {
          box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.34);
        }
      }
    }
  }
}
</style>
