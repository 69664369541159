<template>
  <div
    :class="[
      'CurrencyCard_root',
      {
        'CurrencyCard_rootCrypto': currencyType === 'crypto' && method !== 'CROSS_ACCOUNT' && method !== 'SWIFT',
        'CurrencyCard_root--imageFull' : ['SWIFT', 'CASHBOX_BREINROCK'].some(el=>el === method),
        'CurrencyCard_root--active': activeMethod === method,
      },
    ]"
    @click="clickHandler"
    :data-method="method"
  >
    <div v-if="activeMethod === method" class="CurrencyCard_checked"><span /></div>
    <div class="CurrencyCard_wrapper">
      <div class="CurrencyCard_container">
        <div class="CurrencyCard_iconWrapper">
          <!-- <SquareCoinIcon size="38" v-if="currencyType === 'crypto' && method !== 'CROSS_ACCOUNT' && method !== 'SWIFT'" :currency="currency" :type="currency === 'UACB-NOV22' ? 'image' : 'svg'" /> -->
          <SquareCoinIcon size="38" v-if="currencyType === 'crypto' && method !== 'CROSS_ACCOUNT' && method !== 'SWIFT'" :currency="currency" />
          <img v-else :src="getProperMethodCard" alt="method" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SquareCoinIcon from '@/components/_common/SquareCoinIcon/SquareCoinIcon';
export default {
  props: {
    currency: {
      type: String,
      required: true,
      default: 'USD',
    },
    currencyType: {
      type: String,
      required: true,
      default: 'crypto',
    },
    method: {
      type: String,
      required: true,
    },
    activeMethod: {
      type: String,
      default: 'GATEWAY',
    },
  },
  components: {
    SquareCoinIcon,
  },
  computed: {
    getProperMethodCard() {
      return this.getProperMethodView(this.method);
    },
  },
  methods: {
    getProperMethodView(method) {
      switch (method) {
        case 'GATEWAY':
          return require('@/assets/img/methods/fiatGateway.svg');
        case 'SEPA':
          return require('@/assets/img/methods/sepa-logo.svg');
        case 'CASHBOX':
          return require('@/assets/img/methods/cashbox.svg');
        case 'SWIFT':
          // return require('@/assets/img/methods/swift.svg');
          return require('@/assets/img/methods/swift-2.svg');
        case 'CROSS_ACCOUNT':
          return require('@/assets/img/methods/kauri.svg');
        case 'CASHBOX_BREINROCK':
          return require('@/assets/img/methods/breinrock.svg');
        default:
          return require('@/assets/img/methods/sepa-logo.svg');
      }
    },
    clickHandler() {
      this.$emit('clickMethod', this.method);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './CurrencyCard.scss';
</style>
