<template>
<div class='blocked'>
  <div class='blocked__title'>WITHDRAWAL TEMPORARILY BLOCKED</div>
  <p class='blocked__description'>For the safety of your assets withdrawals are restricted for 24 hours after the password change</p>
</div>
</template>

<script>
export default {
  name: 'WithdrawBlocked',
}
</script>

<style lang='scss'
       scoped
>
@import '@/assets/scss/_variables.scss';
.blocked {
  flex-grow: 1;
  margin: 0 0 20px;
  padding: 25px 33px;
  background: #FF556D;
  box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.1);
  border-radius: 4px;
  form & {
    margin: 0;
  }
  @media only screen and (min-width: $tablet-width) {
    margin: 0 30px 0 0;
  }  
}

.blocked__title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.blocked__description {
  margin: 0 auto;
  max-width: 325px;
  display: flex;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  text-align: left;
  
  &::before {
    content: "i";
    margin-top: 2px;
    margin-right: 5px;
    flex: 0 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    border-radius: 50%;
    background: #fff;
    color: #FF556D;
    font-size: 8px;
    line-height: 100%;
    font-weight: 700;
  }
}
</style>