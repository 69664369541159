<template>
  <div class="Cashbox">
    <div class="md:flex md:justify-between md:items-start">
      <div
        v-if='checkDisableDescription === "NOT_VERIFIED"'
        class='WalletMethod_verification'
      >
        <PassVerification :operation='action' />
      </div>
      <WithdrawBlocked v-else-if='checkDisableDescription === "BLOCKED"' />
      <template v-else>
        <div v-if="isConfirmationStepsActive" class="grow m-[0_0_20px] md:m-[0_30px_0_0]">
          <v-form ref="form" autocomplete="false">
            <div class="WalletMethod_title">Breinrock</div>
            <div class="WalletMethod_separator !mb-[20px]"></div>
            <TextFieldInput
              :value="depositAndWithdrawalCashboxBreinrockForm.username"
              name="username"
              label="Breinrock username"
              :rules="[...inputRules.required]"
              @input="handleInput(arguments[0], 'username')"
              hideDetails
              class="mb-[15px]"
            />
            <TextFieldInput
              :value="depositAndWithdrawalCashboxBreinrockForm.account_number"
              name="account_number"
              label="Breinrock account number"
              :rules="[...inputRules.required]"
              @input="handleInput(arguments[0], 'account_number')"
              hideDetails
              class="mb-[15px]"
            />
            <TextFieldInput
              :value="depositAndWithdrawalCashboxBreinrockForm.amount"
              name="amount"
              type="number"
              label="You get"
              :rules="[...inputRules.required]"
              @input="handleInput(arguments[0], 'amount')"
              hideDetails
              class="mb-[15px]"
            />
            <div class="TextBtn_root"
                @click="showComment = !showComment">
              <SvgIcon
                class="TextBtn_icon"
                icon="iconComment"
              ></SvgIcon>
              Add comments
            </div>
            <transition name="fade" mode="out-in">
              <div class="mt-[10px]"
                  v-if="showComment">
                <TextArea
                  :value="depositAndWithdrawalCashboxBreinrockForm.comment"
                  name="comment"
                  label="Comment"
                  hideDetails
                  @input="handleInput(arguments[0], 'comment')"
                />
              </div>
            </transition>
            <OutlineButton
              class="Cashbox_submit"
              :text="isDeposit ? 'Deposit' : 'Withdrawal'"
              fullWidth
              currencyType
              :currency="currency"
              @click="handleSubmit"
            />
          </v-form>
        </div>
        <ConfirmCashboxBreinrock
          v-if="confirmStep"
          :currency="currency"
          :buttonDisableConfirm="buttonDisableConfirm"
          @cancelCashboxBreinrock="handleCancelConfirm"
          @confirmCashboxBreinrock="handleConfirmed"
        />
      </template> 
      <!-- <div class="shirk-0 md:basis-[390px] mt-[20px] md:mt-[45px] 2xl:mt-[85px]"> -->
      <div class="shirk-0 md:basis-[390px]">
          <LimitFeePanel 
            :operation="action" 
            :limits="prepareLimitsInfo" 
            :fees="prepareFeeInfo" 
            :currency="currency" 
            :method="'CASHBOX_BREINROCK'" 
          />
        </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import inputRules from '@/utils/inputRules';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import { validateForm } from '@/mixins/validateForm';
import SvgIcon from "@/components/_common/SvgIcon";
import TextArea from '@/components/_common/FormElements/TextArea/TextArea';
import LimitFeePanel from '@/components/_common/LimitFeePanel/LimitFeePanel';
import { getLimitsByCurrency } from '@/store/exchange/_helpers/exchangeLimits';
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee';
import ConfirmCashboxBreinrock from './ConfirmCashboxBreinrock.vue';
import { showAppNotification } from '@/utils/appNotification';
import PassVerification from '@/components/_common/PassVerification/PassVerification';
import WithdrawBlocked from "@/components/_common/WithdrawBlocked";
import { allActiveDepositMethods } from '@/views/Application/Account/Exchange/Wallet/_helpers/depositRules';

export default {
  props: {
    currency: {
      type: String,
      default: 'UAH',
    },
    tempDisableSubmit: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: 'deposit',
    },
  },
  components: {
    TextFieldInput,
    OutlineButton,
    SvgIcon,
    TextArea,
    LimitFeePanel,
    ConfirmCashboxBreinrock,
    PassVerification,
    WithdrawBlocked,
  },
  mixins: [validateForm],
  data: () => ({
    inputRules,
    showComment: false,
    confirmStep: false,
    buttonDisableConfirm: false,
  }),
  computed: {
    ...mapState('depositAndWithdrawal', ['depositAndWithdrawalCashboxBreinrockForm']),
    ...mapGetters( 'user', [ 'getStatusDisableDescriptionWithdrawal' ] ),
    isDeposit() {
      return this.action === 'deposit';
    },
    prepareLimitsInfo() {
      const limitsObject = getLimitsByCurrency({
        currency: this.currency,
        type: 'CASHBOX',
        operation: this.action,
      });
      return limitsObject;
    },
    prepareFeeInfo() {
      const feeObject = calculateOperationFee({
        currency: this.currency,
        type: 'CASHBOX',
        operation: this.action,
      });
      return feeObject;
    },
    checkDisableDescription() {
      if (this.action === 'deposit') {
        const currentMethod = allActiveDepositMethods(this.currency)[ 'CASHBOX_BREINROCK' ];
        const { disable_description } = currentMethod;
        if (disable_description === 'ACCOUNT_VERIFICATION_REQUIRED' && !this.isVerified) {
          return "NOT_VERIFIED";
        }
        return "OK";
      } else {
        return this.getStatusDisableDescriptionWithdrawal( this.currency, 'CASHBOX' )
      }
    },
    isConfirmationStepsActive() {
      return !this.confirmStep
    },
  },
  methods: {
    ...mapActions('depositAndWithdrawal', ['updateCashboxBreinrockForm', 'apiDepositCashboxBreinrock', 'apiWithdrawCashboxBreinrock']),
    handleInput(value, field) {
      this.updateCashboxBreinrockForm({
        field,
        value,
      });
    },
    handleSubmit() {
      this.validateForm().then(() => {
        this.confirmStep = true
      })
    },
    handleConfirmed() {
      this.buttonDisableConfirm = true
      if (this.isDeposit) {
        this.apiDepositCashboxBreinrock(this.currency)
        .then(() => {
          this.confirmStep = false
          showAppNotification('success', 'Deposit successfully created!');
        })
        .catch( () => {
          setTimeout( () => {
            this.buttonDisableConfirm = false
          }, 2000 )
        } )
      } else {
        this.apiWithdrawCashboxBreinrock(this.currency)
        .then(() => {
          this.confirmStep = false
          showAppNotification('success', 'Withdrawal successfully created!');
        })
        .catch( () => {
          setTimeout( () => {
            this.buttonDisableConfirm = false
          }, 2000 )
        } )
      }
    },
    handleCancelConfirm() {
      this.confirmStep = false
    },
  },
};
</script>

<style lang="scss" scoped>
@import './CashboxBreinrock.scss';
</style>
